@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.dark .dark\:booking-confirmation .ant-modal-content {
    background-color: rgb(36, 36, 36);
}
.dark .dark\:booking-confirmation .ant-modal-header {
    background: transparent!important;
}
.dark .dark\:booking-confirmation .ant-modal-title {
    color: #fff!important;
}